<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base">
      <vx-card>
        <div class="vx-row">
          <div class="vx-col lg:w-1/4 md:w-full">
            <label class="text-sm mb-2"><strong>Keyword</strong></label>
            <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-2" v-model="searchQuery" placeholder="Search..." />
          </div>
          <div class="vx-col lg:w-1/4 md:w-full">
            <label class="text-sm mb-2"><strong>Category</strong></label>
            <v-select class="w-full" :value="selectedCategory" @input="setselectedCategory" :options="translationCategoryOptions" />
          </div>
          <div class="vx-col lg:w-1/2 md:w-full">
            <label class="text-sm mb-2" v-if='selectedCategoryFull'><strong>Category Description</strong></label>
            <p class="mt-2">{{selectedCategoryFull && selectedCategoryFull.description}}</p>
          </div>
        </div>
        <div>
        </div>
        <ag-grid-vue
          ref="agGridTable"
          :components="cellRendererComponents"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="filteredTranslationList"
          :gridOptions="gridOptions"
          :autoGroupColumnDef="autoGroupColumnDef"
          rowSelection="single"
          :pagination="true"
          :context="context"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="false"
          @selection-changed="onSelectionChanged"
        >
        </ag-grid-vue>

      </vx-card>
      <translation-edit-modal v-if="showEditModal"
        :applicationId="applicationId"
        :translationData="currentTranslationInEdit"
        @onSave="handleSave"
        @onCancel="showEditModal=false"
      />
    </div>
  </div>
</template>

<script>
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import Vue from "vue"
import CellRendererTranslationActions from './cell-renderers/CellRendererTranslationActions.vue'
import CellRendererStatusIcon from './cell-renderers/CellRendererStatusIcon.vue'
import TranslationEditModal from './TranslationEditModal.vue'

export default {
  components: {
    AgGridVue,
    CellRendererTranslationActions,
    CellRendererStatusIcon,
    TranslationEditModal
  },
  props: {
    applicationId: { required: true }
  },
  data () {
    return {
      translationList: [],
      categoryList: [],
      autoGroupColumnDef: null,
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererTranslationActions,
        CellRendererStatusIcon
      },
      searchQuery: '',
      selectedCategory: {},
      context: null,
      showEditModal: false,
      currentTranslationInEdit: {},
    }
  },
  computed: {
    filteredTranslationList () {
      if(!this.searchQuery && !(this.selectedCategory && this.selectedCategory.code)) {
        return this.translationList
      }

      const lodash = this.$_
      const searchText = this.searchQuery.toLowerCase()
      return this.$_.filter(
        this.translationList, x => {
          const foundKeyword = lodash.includes(x.name && x.name.toLowerCase(), searchText) 
            || lodash.includes(x.key && x.key.toLowerCase(), searchText) 
            || lodash.includes(x.defaultValue && x.defaultValue.toLowerCase(), searchText )
            
          const categoryFound = !this.selectedCategory || !this.selectedCategory.code || x.categoryId == this.selectedCategory.code
          return foundKeyword && categoryFound
        }
      )
    },
    translationCategoryOptions () {
      return this.categoryList.map(x => ({
        label: x.name,
        code: x.id 
      }))
    },
    selectedCategoryFull () {
      return this.categoryList.find(x => x.id == (this.selectedCategory && this.selectedCategory.code))
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 50
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    }
  },
  created () {
    //Check if user has acccess to the app
    if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.$route.params.applicationId)) ) {
      this.$router.push('/error-404')
    }
    
    this.$vs.loading()
      this.$http.get(`/api/translations/apps/${this.applicationId}`)
      .then(response => {
          this.translationList = response.data.translations
          this.categoryList = response.data.categories
      })
      .catch(error => console.error(error))
      .finally(() => this.$vs.loading.close())  
  },
  beforeMount() {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { field: 'id', hide: true },
      { headerName: '', width: 60, cellRendererFramework: Vue.extend(CellRendererTranslationActions), suppressSizeToFit: true },
      { headerName: 'Name', field: 'name', width: 400,  suppressSizeToFit: true },
      { headerName: 'Description', field: 'description', width: 150,  suppressSizeToFit: false },
      { headerName: 'Category', field: 'categoryName', width: 250,  suppressSizeToFit: true },
      { headerName: 'Status', width: 100, cellRendererFramework: Vue.extend(CellRendererStatusIcon), suppressSizeToFit: true },
      { field: 'translationStatus', hide: true },
      { fieldId: 'defaultValue', hide: true},
      { fieldId: 'applicationId', hide: true}
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 60,
      sortable: true,
      resizable: true,
      suppressMenu: true
    }

    this.autoGroupColumnDef = { minWidth: 200 };
    this.context = { componentParent: this }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
  },
  
  methods: {
    onAdd () {
      // this.$router.push({ name: 'company-applications-addition', params: { companyId: this.activeUserInfo.companyId}})
    },

    onSelectionChanged() {
      var selectedRows = this.gridApi.getSelectedRows();
    },
    setselectedCategory(value) {
      this.selectedCategory = value;
    },
    async onEditTranslation (translationId) {
      this.$vs.loading()
      const response = await this.$http.get(`/api/translations/apps/${this.applicationId}/id/${translationId}`)
      this.currentTranslationInEdit.translationId = translationId
      this.currentTranslationInEdit.translationList = response.data.translations
      this.currentTranslationInEdit.translationName = response.data.name,
      this.currentTranslationInEdit.translationDescription = response.data.description
      this.currentTranslationInEdit.translationDefaultValue = response.data.defaultValue
      this.showEditModal = true

      this.$vs.loading.close()
    },
    handleSave() {
      this.showEditModal = false
    },
  }
}
</script>

<style>

</style>
