<template>
  <travio-modal width='550' height="460" zIndex="99999">
    <h3 slot="header">{{headingTitle}}</h3>
    <div slot="body">
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <p class="text-sm"><strong>Description</strong></p>
          <p class="mt-2">{{ translationDescription }}</p>
        </div>
        
        <div class="vx-col w-full mt-6">
          <p class="text-sm"><strong>Default Value</strong></p>
          <p class="mt-2">{{ translationDefaultValue }}</p>
        </div>
      </div>

      <ag-grid-vue
        ref="agGridTable"
        :components="cellRendererComponents"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="translationList"
        :gridOptions="gridOptions"
        :rowSelection="rowSelection"
        :pagination="false"
        :suppressPaginationPanel="true"
        :style="{ height:'300px' }"
      >
      </ag-grid-vue>
    </div>
    
    <div slot="footer" class="flex flex-wrap justify-end">
      <vs-button class="" color="danger" @click="close" type="filled">Cancel</vs-button>      
      <vs-button class="ml-2" color="primary" @click="save" type="filled">Save</vs-button>      
    </div>
  </travio-modal>
</template>

<script>
import TravioModal from '@/components/travio-pro/TravioModal.vue'
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import CellRendererTranslationValue from './cell-renderers/CellRendererTranslationValue.vue'
import Vue from 'vue'

export default {
  components: {
    TravioModal,
    AgGridVue,
    CellRendererTranslationValue
  },
  data () {
    return {
      translationId: 0,
      translationName: '',
      translationDescription: '',
      translationDefaultValue: '',
      translationList: [],
      autoGroupColumnDef: null,
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererTranslationValue
      },
      hasLoaded: false
    }
  },
  props: {
    applicationId: { required: true }, 
    translationData: { required: true, type: Object }, 
  },
  computed: {
    headingTitle() {
      return 'Manage Translations for ' + 'Landmark Pill'
    },
  },
  created () {
    this.translationId = this.translationData.translationId
    this.translationList = [...this.translationData.translationList]
    this.translationName = this.translationData.translationName
    this.translationDescription = this.translationData.translationDescription
    this.translationDefaultValue = this.translationData.translationDefaultValue
  },
  beforeMount() {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { field: 'translationId', hide: true },
      { field: 'appId', hide: true },
      { field: 'value', hide: true },
      { headerName: 'Language', field: 'languageCode', sortable: true, width: 130, suppressSizeToFit: true },
      { headerName: 'Value', 
        sortable: false,
        suppressSizeToFit: false,
        width: 300,
        cellRendererFramework: Vue.extend(CellRendererTranslationValue) 
      }
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      autoHeight: true,
      resizable: true,
      suppressMenu: true
    }
    this.rowSelection = 'single';
  },
  mounted (){
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();

  },
  methods: {
    async save () {
      this.$vs.loading()
      try {
        await this.$http.post(`/api/translations/apps/${this.applicationId}/id/${this.translationId}`,
          { translations: this.translationList })
        this.$_notifySuccess('Translation successfully saved');
        this.$vs.loading.close()
        this.$emit('onCancel')
        
      } catch (error) {
        this.$_notifyFailureByResponseData(error.response.data)
      }
      this.$vs.loading.close()
    },
    close () {
      this.$emit('onCancel')
    }
  }
}
</script>

<style>

</style>