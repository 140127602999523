<template>
  <div>
    <feather-icon title="Edit" icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="onEdit" />
  </div>
</template>

<script>
import _ from 'lodash'
import axios from '@/axios'

export default {
  data () {
    return {
      
    }
  },
  computed: {
  },
  methods: {
    onEdit () {
      // this.$router.push({ name: 'application-translation-edit', 
      //   params: { 
      //     applicationId: this.params.data.applicationId,
      //     translationId: this.params.data.id
      //   }
      // })
      this.params.context.componentParent.onEditTranslation(this.params.data.id) 
    },
  }
}
</script>
