<template>
  <div class="flex items-center w-full cell-renderer-select-settings-container">
      <vs-input :value="inputValue" @change="setSetting" class="w-full" />
  </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  name: 'CellRendererIntegrationSettingsValue',
  components: {
    vSelect
  },
  data () {
    return {
      inputValue: ''
    }
  },
  computed: {

  },
  mounted () {
    // Single
    this.inputValue = this.params.data.value
  },
  methods: {
    setSetting (e) {
      this.params.data.value = e.target.value
      this.inputValue = e.target.value
      // only mark as dirty if form is update
      // this.params.data.isDirty = !this.params.data.isNewSettings
      // this.params.context.componentParent.onDirty()
    }
  }
}
</script>

<style>
/* Make sure the checkbox centers vertically in the row*/
.cell-renderer-select-settings-container {
  height: 100%;
}
/* Make drop-down options visible and fill up the entire cell */
.cell-renderer-select-settings-container .v-select {
  width: 100% !important;
}

</style>