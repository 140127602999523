<template>
    <div>
      <feather-icon :title="params.data.translationStatus" :icon="statusIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary" />
    </div>
</template>

<script>

export default {
  components: {
  },
  data () {
    return {

    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    statusIcon () {
      this.params.data.translationStatus

      switch (this.params.data.translationStatus) {
        case "No Entries":
          return "XIcon"
          break;
        case "Completed":
          return "CheckIcon"
          break;
        case "Partial":
          return "TriangleIcon"
          break;
        default:
          return "XIcon"
      }
    }
  },
  methods: {
    
  }
}
</script>
